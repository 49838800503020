import { AddClickApi } from "../../API/Clicks";
import {
  CreateLeadApi,
  EditLeadApi,
  GetAreaOfInterestApi,
  GetNoAuthLeadDetailsApi,
} from "../../API/Leads";
import { emptyFunction } from "../../helper/helperFunction";
import { setLeadsDetails } from "../slices/leadsSlice";
import { createThunk } from "./thunk";

/* ================== Fetch Leads Details ================================ */
const fetchLeadDetails = async (
  { id, setIsLoading = emptyFunction, cb = emptyFunction, setCurrentLead },
  { dispatch }
) => {
  setIsLoading(true);
  const { data, message, success } = await GetNoAuthLeadDetailsApi(id);
  if (!success) throw new Error(message);
  dispatch(setLeadsDetails(data));
  cb(data);
  setCurrentLead(data);
  setIsLoading(false);
};

/* ================== Add Link Clicks ================================ */
const AddLinkClicks = async ({ data, setIsLoading = emptyFunction }) => {
  setIsLoading(true);
  const { message, success } = await AddClickApi(data);
  if (!success) throw new Error(message);
  setIsLoading(false);
};

/* ================== Edit Leads  ================================ */

const editLeads = async (
  { id, leadData, setIsLoading = emptyFunction, cb = emptyFunction },
  { dispatch }
) => {
  setIsLoading(true);
  const { message, success, data } = await EditLeadApi(id, leadData);
  if (!success) throw new Error(message);
  dispatch(setLeadsDetails(data));
  cb(data);
  setIsLoading(false);
};

/* ================== Area of  Interest  ================================ */

const getAreaOfInterest = async (
  {
    query,
    setIsLoading = emptyFunction,
    cb = emptyFunction,
    setAreaOfInterestData = emptyFunction,
    setAreaOfInterestCount,
    fetchMore = false,
  },
  { dispatch }
) => {
  setIsLoading(true);
  const { success, data, message } = await GetAreaOfInterestApi(query);
  if (!success) throw new Error(message);
  const { locations, count } = data;

  fetchMore
    ? setAreaOfInterestData((prev) => [...prev, ...locations])
    : setAreaOfInterestData(locations);
  setAreaOfInterestCount(count);

  cb(data);
  setIsLoading(false);
};
/* ================== Create Leads  ================================ */

const createLead = async (
  { leadData, setIsLoading = emptyFunction, cb = emptyFunction ,isNewLead},
  { dispatch }
) => {      console.log("isNewLea inside new func5")
  setIsLoading(true);

  const { message, success, data } = await CreateLeadApi(leadData,isNewLead);
        console.log("isNewLea inside new func4")
  if (!success) throw new Error(message);
  dispatch(setLeadsDetails(data));
  cb(data);
  setIsLoading(false);
};

export const handleFetchLeadDetails = createThunk(
  "leads/list",
  fetchLeadDetails,
  ({ setIsLoading, errorCallback }) => {
    if (errorCallback) errorCallback();
    setIsLoading(false);
  }
);

export const handleEditLead = createThunk(
  "leads/edit",
  editLeads,
  ({ setIsLoading }) => {
    setIsLoading(false);
  }
);

export const handleCreateLead = createThunk(
  "leads/create",
  createLead,
  ({ setIsLoading }) => {
    setIsLoading(false);
  }
);

export const handleGetAreaOfInterest = createThunk(
  "leads/areaOfInterest",
  getAreaOfInterest,
  ({ setIsLoading }) => {
    setIsLoading(false);
  }
);

export const handleAddClicks = createThunk(
  "leads/addClicks",
  AddLinkClicks,
  ({ setIsLoading, errorCallback }) => {
    if (errorCallback) errorCallback();
    setIsLoading(false);
  }
);
