import { createSearchParams } from "../../helper/helperFunction";
import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";

const url = import.meta.env.VITE__APP_API;

export const GetLeadDetailsApi = (id) => {
  const config = {
    method: "get",
    url: url + apiEndPoints.LEADS.leadDetails.replace(":leadId", id),
    headers: setHeaders("application/json", true),
  };
  return asyncFunc(config);
};

export const GetNoAuthLeadDetailsApi = (id) => {
  const config = {
    method: "get",
    url: url + apiEndPoints.LEADS.noAuthLeadDetails.replace(":leadId", id),
    headers: setHeaders("application/json", false),
  };
  return asyncFunc(config);
};

export const EditLeadApi = (id, data) => {
  const config = {
    method: "put",
    url: url + apiEndPoints.LEADS.edit.replace(":leadId", id),
    headers: setHeaders("application/json", false),
    data,
  };
  return asyncFunc(config);
};

export const CreateLeadApi = (data,isNewLead) => {
  console.log(url,isNewLead,'isNewLead')
  const config = {
    method: "post",
    url: isNewLead? url +apiEndPoints.LEADS.createWithOutAuth: url +apiEndPoints.LEADS.create,
    headers: setHeaders("application/json",isNewLead?false: true),
    data,
  };
  return asyncFunc(config);
};

export const CreateLeadWithoutAuthApi = (data) => {
  const config = {
    method: "post",
    url: url + apiEndPoints.LEADS.createWithOutAuth,
    headers: setHeaders("application/json", false),
    data,
  };
  return asyncFunc(config);
};


export const GetAreaOfInterestApi = (query) => {
  const searchParams = createSearchParams(query);

  const s = query?.search?.length
    ? apiEndPoints.LEADS.interestSearch
    : apiEndPoints.LEADS.areaOfInterest;
  const config = {
    method: "get",
    url: url + s + searchParams,
  };
  return asyncFunc(config);
};

export const NotInterestedLeadApi = (data) => {
  const config = {
    method: "put",
    url: url + apiEndPoints.LEADS.notInterested,
    headers: setHeaders("application/json", false),
    data,
  };
  return asyncFunc(config);
};
